import AnacondaWhite from '@images/brands/anaconda/anacondaWhite.svg';
import HomeXAnaconda from '@images/brands/anaconda/home_x_anaconda.png';
import BeresfordsWhite from '@images/brands/beresfords/beresfordsWhite.svg';
import HomeXBeresfords from '@images/brands/beresfords/home_x_beresfords.png';
import BishopsWhite from '@images/brands/bishops/bishopsWhite.png';
import HomeXBishops from '@images/brands/bishops/home_x_bishops.png';
import ChestertonsWhite from '@images/brands/chestertons/chestertonsWhite.svg';
import HomeXChestertons from '@images/brands/chestertons/home_x_chestertons.png';
import CroftWhite from '@images/brands/croft/croftWhite.svg';
import DefaultWhite from '@images/brands/default/defaultWhite.svg';
import HomeXMcgrath from '@images/brands/mcgrath/home_x_mcgrath.png';
import McgrathWhite from '@images/brands/mcgrath/mcgrathWhite.svg';
import HomeXUpstix from '@images/brands/upstix/home_x_upstix.png';
import UpstixWhite from '@images/brands/upstix/upstixWhite.svg';

export interface BrandImage {
  url: string;
  height: number;
  width: number;
}

export type CountryCode = 'GBR' | 'AUS';

export interface Brand {
  id: PartnerBrand;
  title: string;
  images: {
    monotone: BrandImage;
    homeX?: BrandImage;
  };
  style: {
    bg: string;
    text: string;
    icon?: string;
  };
  trackedLinks?: {
    safehouseInsurance?: string;
  };
  countryCode: CountryCode;
}

export enum PartnerBrand {
  Anaconda = 'ANACONDA',
  Beresfords = 'BERESFORDS',
  Bishops = 'BISHOPS',
  Chestertons = 'CHESTERTONS',
  Croft = 'CROFT',
  Default = 'HOME',
  McGrath = 'MC_GRATH',
  Upstix = 'UPSTIX',
}

export type PartnerBrandKey =
  | 'anaconda'
  | 'beresfords'
  | 'bishops'
  | 'chestertons'
  | 'croft'
  | 'default'
  | 'mc_grath'
  | 'upstix';

const BRANDING_MAP: Readonly<Record<PartnerBrandKey, Brand>> = {
  anaconda: {
    id: PartnerBrand.Anaconda,
    title: 'Anaconda Cut',
    images: {
      monotone: {
        height: 37,
        width: 139,
        url: AnacondaWhite,
      },
      homeX: {
        height: 49,
        width: 331,
        url: HomeXAnaconda,
      },
    },
    style: { bg: 'bg-brands-anaconda', text: 'text-brands-anaconda' },
    trackedLinks: {
      safehouseInsurance: 'https://www.safehouse-getquote.co.uk/TCI?referralRef=home-t9',
    },
    countryCode: 'GBR',
  },
  beresfords: {
    id: PartnerBrand.Beresfords,
    title: 'Beresfords',
    images: {
      monotone: {
        height: 23,
        width: 152,
        url: BeresfordsWhite,
      },
      homeX: {
        height: 45,
        width: 339,
        url: HomeXBeresfords,
      },
    },
    style: { bg: 'bg-brands-beresfords', text: 'text-brands-beresfords' },
    trackedLinks: {
      safehouseInsurance: 'https://www.safehouse-getquote.co.uk/TCI?referralRef=home-t3',
    },
    countryCode: 'GBR',
  },
  bishops: {
    id: PartnerBrand.Bishops,
    title: 'Bishops',
    images: {
      monotone: {
        height: 59,
        width: 56,
        url: BishopsWhite,
      },
      homeX: {
        height: 57,
        width: 249,
        url: HomeXBishops,
      },
    },
    style: { bg: 'bg-brands-bishops', text: 'text-brands-bishops' },
    countryCode: 'GBR',
  },
  chestertons: {
    id: PartnerBrand.Chestertons,
    title: 'Chestertons',
    images: {
      monotone: {
        height: 50,
        width: 141,
        url: ChestertonsWhite,
      },
      homeX: {
        height: 63,
        width: 363,
        url: HomeXChestertons,
      },
    },
    style: { bg: 'bg-brands-chestertons', text: 'text-brands-chestertons' },
    trackedLinks: {
      safehouseInsurance: 'https://www.safehouse-getquote.co.uk/TCI?referralRef=home-t10',
    },
    countryCode: 'GBR',
  },
  croft: {
    id: PartnerBrand.Croft,
    title: 'Croft',
    images: {
      monotone: {
        height: 23,
        width: 133,
        url: CroftWhite,
      },
    },
    style: { bg: 'bg-brands-croft', text: 'text-brands-croft' },
    trackedLinks: {
      safehouseInsurance: 'https://www.safehouse-getquote.co.uk/TCI?referralRef=home-t11',
    },
    countryCode: 'GBR',
  },
  mc_grath: {
    id: PartnerBrand.McGrath,
    title: 'McGrath',
    images: {
      monotone: {
        height: 28,
        width: 152,
        url: McgrathWhite,
      },
      homeX: {
        width: 264,
        height: 35,
        url: HomeXMcgrath,
      },
    },
    style: {
      bg: 'bg-brands-mcgrath-secondary',
      text: 'text-brands-mcgrath',
      icon: 'fill-brands-mcgrath-primary',
    },
    countryCode: 'AUS',
  },
  upstix: {
    id: PartnerBrand.Upstix,
    title: 'Upstix',
    images: {
      monotone: {
        height: 36,
        width: 162,
        url: UpstixWhite,
      },
      homeX: {
        height: 47,
        width: 350,
        url: HomeXUpstix,
      },
    },
    style: { bg: 'bg-brands-upstix', text: 'text-brands-upstix' },
    countryCode: 'GBR',
  },
  default: {
    id: PartnerBrand.Default,
    title: 'Home',
    images: {
      monotone: {
        height: 41,
        width: 119,
        url: DefaultWhite,
      },
    },
    style: { bg: 'bg-home', text: 'text-home' },
    countryCode: 'GBR',
  },
};

// Check if brand is australia based
export function isAustraliaBrand(brand?: PartnerBrand): boolean {
  if (!brand || typeof brand !== 'string') return false;
  return [PartnerBrand.McGrath].includes(brand.toUpperCase() as PartnerBrand);
}

export function getBrandingConfig(partner?: string): Brand | null {
  if (!partner) {
    return null;
  }

  const key = partner.toLowerCase() as PartnerBrandKey;
  return BRANDING_MAP[key] ?? null;
}
