import { lazy } from 'react';

import { PrivacyAustralia } from '@pages/aus/privacy';
import { TermsAustralia } from '@pages/aus/terms';
import { ChatPage } from '@pages/chat';
import { ForgotPasswordConfirm } from '@pages/forgotPasswordConfirm';
import { HomeIntelligencePage } from '@pages/homeIntelligence';
import { Move } from '@pages/move';
import { ServicesBroadbandPage } from '@pages/services/Broadband';
import { ServicesConveyancingPage } from '@pages/services/Conveyancing';
import { SignupPage } from '@pages/signup';
import { WelcomePage } from '@pages/welcome';

import { Routes } from './constants';
import { Dashboard } from './pages/dashboard';
import { SignIn } from './pages/signin';

const Onboarding = lazy(() => import('./pages/onboarding'));
const OnboardingConfirmation = lazy(() => import('./pages/onboardingConfirmation'));
const Terms = lazy(() => import('./pages/terms'));
const ForgotPasswordUpdate = lazy(() => import('./pages/forgotPasswordUpdate'));
const ForgotPasswordMaybe = lazy(() => import('./pages/forgotPasswordMaybe'));
const Privacy = lazy(() => import('./pages/privacy'));

type RouteConfiguration = {
  path: string;
  title: string;
  component: any;
  private?: boolean;
  public?: boolean;
};

export const routeConfig: RouteConfiguration[] = [
  {
    path: Routes.TERMS,
    title: 'Terms & Conditions',
    component: Terms,
    private: true,
    public: true,
  },
  {
    path: Routes.PRIVACY,
    title: 'Privacy Policy',
    component: Privacy,
    private: true,
    public: true,
  },
  {
    path: Routes.AUSTRALIA_TERMS,
    title: 'Terms & Conditions',
    component: TermsAustralia,
    private: true,
    public: true,
  },
  {
    path: Routes.AUSTRALIA_PRIVACY,
    title: 'Privacy Policy',
    component: PrivacyAustralia,
    private: true,
    public: true,
  },
  {
    path: Routes.SIGNIN,
    title: 'Sign in',
    component: SignIn,
    public: true,
  },
  {
    path: Routes.WELCOME,
    title: 'Welcome',
    component: WelcomePage,
    public: true,
  },
  {
    path: Routes.FORGOT_PASSWORD,
    title: 'Forgot Password',
    component: ForgotPasswordMaybe,
    public: true,
  },
  {
    path: Routes.CONFIRM_FORGOT_PASSWORD,
    title: 'Confirm Forgot Password',
    component: ForgotPasswordConfirm,
    public: true,
  },
  {
    path: Routes.UPDATE_PASSWORD,
    title: 'Update Password',
    component: ForgotPasswordUpdate,
    public: true,
  },
  {
    path: Routes.INVITATIONS,
    title: 'Welcome to Home',
    component: Onboarding,
    public: true,
  },
  {
    path: Routes.CONFIRMATION,
    title: 'Welcome to Home',
    component: OnboardingConfirmation,
    public: true,
  },
  {
    path: Routes.SERVICES_CONVEYANCING,
    title: 'Conveyancing',
    component: ServicesConveyancingPage,
    private: true,
  },

  {
    path: Routes.SERVICES_BROADBAND,
    title: 'Broadband',
    component: ServicesBroadbandPage,
    private: true,
  },
  {
    path: Routes.CHAT,
    title: 'Chat',
    component: ChatPage,
    private: true,
  },
  {
    path: Routes.SIGNUP,
    title: 'Sign up',
    component: SignupPage,
    public: true,
  },
  {
    path: Routes.HOME_INTELLIGENCE,
    title: 'Home Intelligence',
    component: HomeIntelligencePage,
    private: true,
  },
  {
    path: Routes.MOVE_PAGE,
    title: 'Move',
    component: Move,
    private: true,
  },
  {
    path: Routes.HOME,
    title: 'Home',
    component: Dashboard,
    private: true,
  },
];

type RedirectConfiguration = {
  from: string;
  to: string;
};

export const routeRedirects: RedirectConfiguration[] = [
  {
    from: '/wizard',
    to: '/wizard/find-a-property',
  },
];
