import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppThunkDispatch } from 'src/App';

import { Button } from '@components/Button';

import { ApiRoutes } from '@constants/index';

import { resetAll } from '@features/common';
import { selectProfile } from '@features/profile/profile';

import { Alert, Chat, Phone } from '@images/icons';

import { checkAuthentication } from '@utils/checkAuthentication';
import customAxios from '@utils/customAxios';

type ErrorProps = {
  title: string;
  text?: string;
  alternativeLink?: {
    action: () => void;
    text: string;
  };
};

export const Error = ({ title, text, alternativeLink }: ErrorProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { value: profile } = useSelector(selectProfile);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const authenticate = async () => {
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        setIsAuthenticated(true);
      }
    };

    authenticate();
  }, []);

  const signOut = async () => {
    const result = await customAxios.post(ApiRoutes.SIGNOUT, {
      withCredentials: true,
    });

    if (result?.status === 200) {
      dispatch(resetAll());
      navigate('/signin');
    }
  };

  return (
    <div className="relative box-border flex min-h-screen w-screen items-center justify-center bg-secondary p-5">
      <main className="flex w-full max-w-sm flex-col items-center gap-10">
        <Alert className="w-24 fill-brand" />
        <h2 className="flex flex-col text-center text-4xl font-medium">
          <>
            {!!profile?.customer.name.firstName && (
              <span>Sorry {profile?.customer.name.firstName}!</span>
            )}
            <span>{title}</span>
          </>
        </h2>
        {text && <p className="text-center text-lg">{text}</p>}
        <div className="flex w-full flex-col items-center gap-4">
          <Button onClick={() => window.location.reload()}>Reload</Button>
          {alternativeLink ? (
            <>
              <p>or</p>
              <button
                className="flex items-center justify-center underline hover:no-underline"
                onClick={() => alternativeLink.action()}
              >
                {alternativeLink.text}
              </button>
            </>
          ) : (
            isAuthenticated && (
              <>
                <p>or</p>
                <button
                  className="flex items-center justify-center underline hover:no-underline"
                  onClick={() => signOut()}
                >
                  Sign out
                </button>
              </>
            )
          )}
        </div>
        <p className="text-center text-lg">
          If the problem persists, speak to a homerunner for help
        </p>
        <div
          className="box-border flex w-full max-w-sm flex-col gap-5 rounded-2xl bg-white p-6 shadow-md"
          aria-labelledby="homerunners-heading"
        >
          <h4 id="homerunners-heading" className="text-2xl font-medium">
            HomeRunners
          </h4>
          <p>
            <strong>Need a hand?</strong> Our team of Home Runners are here to help.
          </p>
          <span
            className="flex cursor-pointer items-center gap-5 underline hover:no-underline"
            onClick={() => window.LiveChatWidget.call('maximize')}
            role="button"
            aria-label="Start a live chat"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') window.LiveChatWidget.call('maximize');
            }}
          >
            <Chat className="h-8 w-8 fill-primary" data-testid="chat-icon" aria-hidden />
            <span>Start a live chat</span>
          </span>
          <a
            className="flex items-center gap-5 underline hover:no-underline"
            href="tel:03300433828"
            aria-label="Call Home Runners at 03300 433 828"
          >
            <Phone className="h-8 w-8 fill-primary" data-testid="phone-icon" aria-hidden />
            <span>03300 433 828</span>
          </a>
        </div>
      </main>
    </div>
  );
};
